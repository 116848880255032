import Fill from 'ol/style/Fill.js';
import Stroke from 'ol/style/Stroke.js';
import Style from 'ol/style/Style.js';
import Text from 'ol/style/Text.js';
import Circle from 'ol/style/Circle.js';
import MultiPoint from 'ol/geom/MultiPoint.js';
import type {FeatureLike} from 'ol/Feature.js';

export function boundaryStyle(feature: FeatureLike, resolution: number): Style[] {
  const count = feature.get('count') ?? 0;

  const geometry = feature.getGeometry();
  const type = geometry?.getType();
  let geometries = null;
  const style = [];
  if (count > 0) {
    if (type === 'Polygon') {
      geometries = geometry?.getInteriorPoint();
    } else if (type === 'MultiPolygon') {
      // discard polygons that are too small
      const points = geometry?.getInteriorPoints().getPoints().filter((point) => {
        return point.getCoordinates()[2] / resolution > 0.0001;
      });
      geometries = new MultiPoint(points.map((point) => point.getFlatCoordinates().flat()));
    }
    style.push(
      new Style({
        geometry: geometries,
        image: new Circle({
          radius: 12,
          fill: new Fill({
            color: 'rgba(250, 19, 21, 0.75)',
          }),
        }),
        text: new Text({
          text: count.toString(),
          font: 'bold 14px sans-serif',
        }),
      })
    );
  }
  style.push(
    new Style({
      stroke: new Stroke({
        color: 'rgba(204, 0, 51, 1.0)',
      }),
      fill: new Fill({
        color: 'rgba(0, 0, 0, 0.001)',
      }),
    })
  );
  return style;
}
