import type {Zones, ZonedProperty, ZoneName} from './types.ts';

import Map from 'ol/Map.js';
import View from 'ol/View.js';
import {defaults as defaultInteractions} from 'ol/interaction.js';
import {defaults as defaultsControls} from 'ol/control.js';
import ScaleLine from 'ol/control/ScaleLine.js';
import TileLayer from 'ol/layer/Tile.js';
import VectorLayer from 'ol/layer/Vector.js';
import VectorSource from 'ol/source/Vector.js';
import XYZ from 'ol/source/XYZ.js';
import MultiPolygon from 'ol/geom/MultiPolygon.js';
import TopoJSON from 'ol/format/TopoJSON.js';
import Feature from 'ol/Feature.js';
import Circle from 'ol/geom/Circle.js';
import {useGeographic, get as getProjection} from 'ol/proj.js';
import {boundaryStyle} from './boundaries.ts';
import {scaleFromCenter} from 'ol/extent.js';
import type Geometry from 'ol/geom/Geometry.js';
import type RenderEvent from 'ol/render/Event.js';

useGeographic();

// From the Naef mapbox account
const MAPBOX_KEY = 'pk.eyJ1Ijoib25lbmFlZiIsImEiOiJjbG1vdzAxbWIxMjVoMnNyMjJzNW85eXF2In0.pu1fpfIzBoMWWNSO3JJDrw';

type Options = {
  container: string | HTMLElement;
  radius: number;
  cantons: number[];
  onChanged: (zones: Zones) => void;
};

// Layers that can be added or removed from the selection
const PICKABLE_LAYERS = ['cantons', 'districts', 'municipalities', 'zips'];

const id_from_layer: Record<ZoneName, string> = {
  cantons: 'canton_id',
  districts: 'district_id',
  municipalities: 'commune_id',
  zips: 'zip',
};

const parent_property: Partial<Record<ZoneName, string>> = {
  districts: 'canton_id',
  municipalities: 'district_id',
  zips: 'commune_id',
};

const parent_layer: Partial<Record<ZoneName, ZoneName>> = {
  districts: 'cantons',
  municipalities: 'districts',
  zips: 'municipalities',
};

const children_layer: Partial<Record<ZoneName, ZoneName>> = {
  cantons: 'districts',
  districts: 'municipalities',
  municipalities: 'zips',
};

export class NaefMap {
  private dataUrl = process.env.DATA_URL;
  private map: Map;
  private view: View;
  private cantons: VectorSource<Feature<MultiPolygon>>;
  private districts: VectorSource<Feature<MultiPolygon>>;
  private municipalities: VectorSource<Feature<MultiPolygon>>;
  private zips: VectorSource<Feature<MultiPolygon>>;
  private lakes: VectorSource<Feature<MultiPolygon>>;
  private properties: VectorSource = new VectorSource();
  private selectedZonesSource: VectorSource = new VectorSource();
  private maskSource: VectorSource = new VectorSource();
  private onChanged: (zones: Zones) => void;
  private radius: number;
  private sources: Record<ZoneName, VectorSource<Feature<MultiPolygon>>>;
  private selectedZones: Record<ZoneName, Set<number>> = {
    cantons: new Set(),
    districts: new Set(),
    municipalities: new Set(),
    zips: new Set(),
  };
  private cantonsWhitelist: number[];

  constructor(options: Options) {
    this.onChanged = options.onChanged;

    this.radius = options.radius;

    this.cantonsWhitelist = options.cantons;

    this.cantons = new VectorSource({
      url: `${this.dataUrl}/cantons.topojson`,
      format: new TopoJSON(),
    });
    this.cantons.set('zone_property', 'canton_id');
    this.cantons.on('featuresloadend', () => {
      this.updatePropertiesCount(this.cantons, 'canton');
      this.updateSelectedZonesLayer();
      this.cantons.forEachFeature((feature) => feature.set('layer_name', 'cantons'));

      const whiteListed = this.cantons.getFeatures().filter((feature) => this.cantonsWhitelist.includes(feature.get('canton_id')));
      this.maskSource.addFeatures(whiteListed.map(feature => new Feature(feature.getGeometry())));

      if (this.lakes.getFeatures().length === 0) {
        this.lakes.on('featuresloadend', () => this.hideLakes());
      } else {
        this.hideLakes();
      }

      const extent = this.maskSource.getExtent();
      scaleFromCenter(extent, 1.1);

      this.view = new View({
        extent: extent,
        constrainOnlyCenter: true,
        minZoom: 7,
      });
      this.map.setView(this.view);
      this.view.fit(extent);

      removeBlacklistedFeatures(this.cantons, this.cantonsWhitelist);
    });
    preload(this.cantons);

    this.districts = new VectorSource({
      url: `${this.dataUrl}/districts.topojson`,
      format: new TopoJSON(),
    });
    this.districts.set('zone_property', 'district_id');
    this.districts.on('featuresloadend', () => {
      this.updatePropertiesCount(this.districts, 'district');
      this.updateSelectedZonesLayer();
      this.districts.forEachFeature((feature) => feature.set('layer_name', 'districts'));
      removeBlacklistedFeatures(this.districts, this.cantonsWhitelist);
    });
    preload(this.districts);

    this.municipalities = new VectorSource({
      url: `${this.dataUrl}/municipalities.topojson`,
      format: new TopoJSON(),
    });
    this.municipalities.set('zone_property', 'commune_id');
    this.municipalities.on('featuresloadend', () => {
      this.updatePropertiesCount(this.municipalities, 'municipality');
      this.updateSelectedZonesLayer();
      this.municipalities.forEachFeature((feature) => feature.set('layer_name', 'municipalities'));
      removeBlacklistedFeatures(this.municipalities, this.cantonsWhitelist);
    });
    preload(this.municipalities);

    this.zips = new VectorSource({
      url: `${this.dataUrl}/zips.topojson`,
      format: new TopoJSON(),
    });
    this.zips.set('zone_property', 'zip');
    this.zips.on('featuresloadend', () => {
      this.updateSelectedZonesLayer();
      this.zips.forEachFeature((feature) => feature.set('layer_name', 'zips'));
      removeBlacklistedFeatures(this.zips, this.cantonsWhitelist);
    });
    preload(this.zips);

    this.lakes = new VectorSource({
      url: `${this.dataUrl}/lakes.topojson`,
      format: new TopoJSON(),
    });

    this.sources = {
      cantons: this.cantons,
      districts: this.districts,
      municipalities: this.municipalities,
      zips: this.zips,
    };

    this.view = new View({
      center: [8.308906, 46.814416],
      zoom: 8,
    });

    this.map = new Map({
      target: options.container,
      interactions: defaultInteractions({
        altShiftDragRotate: false,
        pinchRotate: false,
      }),
      controls: defaultsControls().extend([new ScaleLine()]),
      view: this.view,
      layers: [
        new TileLayer({
          source: new XYZ({
            url: `https://api.mapbox.com/styles/v1/onenaef/clsaj61iu00z201qqgdysbp29/tiles/512/{z}/{x}/{y}?access_token=${MAPBOX_KEY}`,
            tileSize: 512,
            attributions:
              "© <a href='https://www.mapbox.com/about/maps/'>Mapbox</a> © <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a>",
          }),
        }),
        new VectorLayer({
          maxZoom: 10,
          style: null,
          source: this.lakes,
        }),
        new VectorLayer({
          maxZoom: 10,
          style: boundaryStyle,
          updateWhileInteracting: true,
          source: this.cantons,
        }),
        new VectorLayer({
          minZoom: 10,
          maxZoom: 11,
          style: boundaryStyle,
          updateWhileInteracting: true,
          source: this.districts,
        }),
        new VectorLayer({
          minZoom: 11,
          maxZoom: 12,
          style: boundaryStyle,
          updateWhileInteracting: true,
          source: this.municipalities,
        }),
        new VectorLayer({
          minZoom: 12,
          style: boundaryStyle,
          updateWhileInteracting: true,
          source: this.zips,
        }),
        new VectorLayer({
          minZoom: 12,
          style: {
            'fill-color': 'rgba(250, 19, 21, 0.5)',
          },
          updateWhileInteracting: true,
          source: this.properties,
        }),
        new VectorLayer({
          style: {
            'fill-color': 'rgba(250, 19, 21, 0.25)',
          },
          updateWhileInteracting: true,
          source: this.selectedZonesSource,
        }),
      ],
    });

    this.map.on('click', (event) => {
      this.map
        .getFeaturesAtPixel(event.pixel)
        .filter((feature) => PICKABLE_LAYERS.includes(feature.get('layer_name')))
        .forEach((feature) => this.toggleFeature(feature as Feature<Geometry>));
    });

    const mask = new VectorLayer({
      style: {
        'fill-color': 'rgba(0, 0, 0, 1.0)',
      },
      className: 'ol-layer mask',
      updateWhileInteracting: true,
      source: this.maskSource,
    });

    mask.on('prerender', maskOut);

    this.map.addLayer(mask);
  }

  // Hide lakes that are in the cantons whitelist
  private hideLakes() {
    this.lakes.forEachFeature((feature) => {
      const cantonIds = feature.get('canton_ids').split(',').map((id: string) => parseInt(id));
      if (cantonIds.some((id: number) => this.cantonsWhitelist.includes(id))) {
        this.maskSource.addFeature(new Feature(feature.getGeometry()));
      }
    });
  }

  public selectZones(zones: Zones) {
    this.selectedZones.cantons.clear();
    this.selectedZones.districts.clear();
    this.selectedZones.municipalities.clear();
    this.selectedZones.zips.clear();
    zones.cantons.forEach((id) => this.selectedZones.cantons.add(id));
    zones.districts.forEach((id) => this.selectedZones.districts.add(id));
    zones.municipalities.forEach((id) => this.selectedZones.municipalities.add(id));
    zones.zips.forEach((id) => this.selectedZones.zips.add(id));

    this.dispatchChanged();
  }

  public setProperties(properties: ZonedProperty[]) {
    this.properties.clear(true);
    this.properties.addFeatures(
      properties.map((property) => {
        return new Feature({
          geometry: new Circle(property.coordinates, this.radius * (1 / 140000)),
          zones: property.zones,
        });
      })
    );
    this.updatePropertiesCount(this.cantons, 'canton');
    this.updatePropertiesCount(this.districts, 'district');
    this.updatePropertiesCount(this.municipalities, 'municipality');
  }

  private updatePropertiesCount(source: VectorSource, type: string) {
    source.forEachFeature((boundary) => boundary.unset('count'));
    const zoneProperty = source.get('zone_property');
    this.properties.forEachFeature((property) => {
      const zoneId = property.get('zones')[type];
      const boundaries = source.getFeatures().filter((boundary) => boundary.get(zoneProperty) === zoneId);
      if (boundaries) {
        boundaries.forEach((boundary) => boundary.set('count', (boundary.get('count') ?? 0) + 1));
      }
    });
  }

  private toggleFeature(feature: Feature<Geometry>) {
    const layer = feature.get('layer_name') as ZoneName;
    const id = feature.get(id_from_layer[layer])
    const zones = this.selectedZones[layer];
    if (zones.has(id)) {
      zones.delete(id);
    } else {
      const {parentLayer, parentId} = this.closestParent(feature);
      if (parentLayer && parentId) {
        // remove from selection because one parent is already selected
        this.selectedZones[parentLayer].delete(parentId);
        let childLayer = children_layer[parentLayer];
        while (childLayer && parent_layer[childLayer] !== layer) {
          const id = feature.get(id_from_layer[childLayer]);
          const parentId = feature.get(parent_property[childLayer]);
          const childrenZones = this.selectedZones[childLayer];
          const siblings = this.getChildrenIds(childLayer, parentId, id);
          siblings.forEach((id) => childrenZones.add(id));
          childLayer = children_layer[childLayer];
        }
      } else {
        // add to selection
        zones.add(id);
        // go down the hierarchy and remove children if possible
        // example: almost all municipalities of a district are selected, then the district is selected
        let childLayer = children_layer[layer];
        while (childLayer) {
          const childrenZones = this.selectedZones[childLayer];
          const childrenIds = this.sources[childLayer]
            .getFeatures()
            .filter((feature) => childrenZones.has(feature.get(id_from_layer[childLayer])))
            .filter((feature) => feature.get(id_from_layer[layer]) === id)
            .map((feature) => feature.get(id_from_layer[childLayer]));
          childrenIds.forEach((id) => childrenZones.delete(id));
          childLayer = children_layer[childLayer];
        }

        // go up the hierarchy and simplify if possible
        // example: all municipalities except one of a district are selected, then this municipality is selected
        let parentLayer = parent_layer[layer];
        while (parentLayer) {
          const layer = children_layer[parentLayer];
          const zones = this.selectedZones[layer];
          const parentId = feature.get(id_from_layer[parentLayer]);
          const parentZones = this.selectedZones[parentLayer];

          // check if all children are selected and simplify if possible
          const children = this.getChildrenIds(layer, parentId);
          if (children.every((child) => zones.has(child))) {
            // all children are selected, remove them and add parent
            children.forEach((id) => zones.delete(id));
            parentZones.add(parentId);
            // console.log('FIXME: check parents if it can be reduced');
          }

          parentLayer = parent_layer[parentLayer];
        }
      }
    }
    this.dispatchChanged();
  }

  // Returns the closest parent that is already selected
  private closestParent(feature: Feature<Geometry>) {
    const layer = feature.get('layer_name') as ZoneName;
    let parentLayer = parent_layer[layer];
    while (parentLayer) {
      const parentId = feature.get(id_from_layer[parentLayer]);
      const parentZones = this.selectedZones[parentLayer];

      if (parentZones.has(parentId)) {
        return {
          parentLayer: parentLayer,
          parentId: parentId,
        };
      }
      parentLayer = parent_layer[parentLayer];
    }
    return {
      parentLayer: null,
      parentId: null,
    };
  }

  private getChildrenIds(layer: ZoneName, parent_id: number, except?: number) {
    return this.sources[layer]
      .getFeatures()
      .filter((feature) => feature.get(parent_property[layer]) === parent_id)
      .filter((feature) => feature.get(id_from_layer[layer]) !== except)
      .map((feature) => feature.get(id_from_layer[layer]));
  }

  private updateSelectedZonesLayer() {
    this.selectedZonesSource.clear(true);
    for (const [layer, zones] of Object.entries(this.selectedZones) as [ZoneName, Set<number>][]) {
      this.sources[layer]
        .getFeatures()
        .filter((feature) => zones.has(feature.get(id_from_layer[layer])))
        .forEach((feature) => {
          this.selectedZonesSource.addFeature(new Feature(feature.getGeometry()));
        });
    }
  }

  private dispatchChanged() {
    this.onChanged({
      cantons: Array.from(this.selectedZones.cantons).sort(),
      districts: Array.from(this.selectedZones.districts).sort(),
      municipalities: Array.from(this.selectedZones.municipalities).sort(),
      zips: Array.from(this.selectedZones.zips).sort(),
    });
    this.updateSelectedZonesLayer();
  }
}

function preload(source: VectorSource) {
  source.loadFeatures([5.140242, 45.398181, 11.47757, 48.230651], 0, getProjection('EPSG:4326')!);
}

function maskOut(event: RenderEvent) {
  const context = event.context as CanvasRenderingContext2D;
  context.globalCompositeOperation = 'copy';
  context.fillStyle = 'rgba(0, 0, 0, 1.0)';
  context.fillRect(0, 0, context.canvas.width, context.canvas.height);
  context.globalCompositeOperation = 'destination-out';
}


function removeBlacklistedFeatures(source: VectorSource, cantons: number[]) {
  source.getFeatures().forEach((feature) => {
    if (!cantons.includes(feature.get('canton_id'))) {
      source.removeFeature(feature);
    }
  });
}
